import React from 'react';
import resume from '../images/resume2023margaretnigh.pdf';

function Resume() {
  return (
    <section id="resume">
      <section className="resume">
      <div className="container">
        <h1>Resume</h1>
        <a href={resume}>Resume PDF</a>
        <div className="resume-content">
          <h3>Education</h3>
          <p>
          Indiana University, Bloomington, IN
          August 2021 - May 2025
          <h4>Computer Science Bachelor of Science</h4>
          Specialization: Artificial Intelligence
         
          
          <h4>Relevent Coursework</h4>
          Data Structures Honors, Intro to Machine Learning, Intro to Artificial Intelligence, Discrete Structures, Software Systems Honors, Technical Game Art and VFX, Animation for Digital Media
          
          <h4>Skills</h4>
          <div className="technologies">
            <span>UX/UI Design</span><span>Figma</span><span>Front-end Development</span><span>React</span><span>Angular</span><span>Javascript</span>
            <span>HTML & CSS</span> <span>Back-end Development</span><span>Node.js</span> <span>Artificial Intelligence/Machine Learning</span>
            <span>Data Analysis</span> <span>Data Structures & Algorithms</span> <span>MongoDB</span>
          </div>
          </p>

          <h3>Work Experience</h3>
          <div className="job">
            <h4>Internal IT Developer Intern</h4>
            <p>World Wide Technology, St. Louis, MO</p>
            <p className="job-dates">May 2023 - August 2023</p>
            <p className="job-description">
            Enhanced and maintained Employee Payroll Application and HR custom applications using Javascript, Angular, and Grails, improving overall functionality and user experience for thousands of employees
            Documented detailed processes and procedures for future developers
            Redesigned user experience of Employee Payroll Application as a Figma prototype
            Explored Azure instance of ChatGPT and designed ChatGPT Assistant and Cultural Ambassador as a Figma prototype
            </p>
          </div>

          <div className="job">
            <h4>Research Assistant</h4>
            <p>Luddy School of Informatics, Computing, and Engineering, Bloomington, IN</p>
            <p className="job-dates">August 2021 - Present</p>
            <p className="job-description">
            Studied robot design affects of children’s perceptions, rights, and privacy through data collection, collaboration, study and content design, and data analysis
            Partnered with UNICEF on the Design and Study of Social Robots for Diverse User Populations.
            </p>
          </div>

          <div className="job">
            <h4>UX Design Intern</h4>
            <p>World Wide Technology, St. Louis, MO</p>
            <p className="job-dates">May 2022 - October 2022</p>
            <p className="job-description">
            Collaborated with UX designers, researchers, and developers to design new website components for design system using Figma
            Brainstormed and wireframed learning path courses, later releasing to thousands of customers and employees
            Enhanced Onboarding and Employee Engagement Dashboard design as a Figma prototype
            Translated design components into codebase pieces using CSS, HTML, and JS
            </p>
          </div>

          

        </div>
      </div>
    </section>
    </section>
  );
}

export default Resume;
