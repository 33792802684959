import React from 'react';

const Artwork = ({ artworks }) => {
  return (
    <div className="art-gallery">
      {artworks.map((artwork, index) => (
        <div key={index} className="artwork">
          <img src={artwork.imageSrc} alt={artwork.title} />
        </div>
      ))}
    </div>
  );
};

export default Artwork;
