import React from 'react';
import Artwork from './Artwork'; // Adjust the path

import hand from "../images/art/hand.jpg"
import hands from "../images/art/hands.jpg"
import landscape from "../images/art/landscape.jpg"
import landscape2 from "../images/art/landscape2.jpg"
import trees from "../images/art/trees.jpg"
const artworks = [
  {
    imageSrc: hand,
  },
  {
    imageSrc: trees,
  },
  {
    imageSrc: landscape,
  },
  {
    imageSrc: landscape2,
  },
  {
    imageSrc: hands,
  },
];

const ArtworkGallery = () => {
  return (
    <div id="artwork_gallery">
    <div className="container">
      <h1>Art Gallery</h1>
      <Artwork artworks={artworks} />
    </div>
    </div>
  );
};

export default ArtworkGallery;
