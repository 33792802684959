import React from 'react';
import Project from './Project';

import regression1 from '../images/graduate-admissions-regression/multiplelinearregression.png';
import regression2 from '../images/graduate-admissions-regression/randomforestregression.png';
import regression3 from '../images/graduate-admissions-regression/output.png';
import regression4 from '../images/graduate-admissions-regression/testing.png';
import regression5 from '../images/graduate-admissions-regression/training.png';
import regression6 from '../images/graduate-admissions-regression/crossvalidation.png';

import recommend1 from '../images/recommending-artworks/1.png';
import recommend2 from '../images/recommending-artworks/2.png';
import recommend3 from '../images/recommending-artworks/3.png';
import recommend4 from '../images/recommending-artworks/4.png';
import recommend5 from '../images/recommending-artworks/5.png';

import dna1 from '../images/dna-alignment-java/1.png';
import dna2 from '../images/dna-alignment-java/2.png';
import dna4 from '../images/dna-alignment-java/4.png';
import routing1 from '../images/routing-wires-java/1.png';
import routing2 from '../images/routing-wires-java/2.png';
import routing3 from '../images/routing-wires-java/3.png';

import pathfinder from '../images/iu-research-other/pathfinder.png';
import robot from '../images/iu-research-other/robotsresearch.png';


// Declare the projects array outside the component.
const projects = [

  {
    title: 'ChatGPT Assistant and Cultural Ambassador Prototype',
    description: 'How can WWT use ChatGPT as an asset to their employees, customers, and processes? We decided to ideate a ChatGPT-based Cultural Ambassador and Assistant. If implemented, the chat bot called Pi would be hosted in an Azure OpenAI instance. We would gather data to fine tune model as a multi-lingual cultural ambassador for internal corporate use. Our project was the Prompt Winner for the Intern Capstone Competition in 2023.',
    responsibilities: 'Met with Software Engineer Managers and Human Resources Leaders to ideate and design the Personas, Planned Features, and Protoype of Pi the People Bot Assistant.',
    technologies: ['UX/UI Design', 'Figma'],
    pagelink: '',
    images: [],
  },

  {
    title: 'Graduate Admissions Regression Models',
    description: 'Regression model to predict how likely an Indian student is admitted to a graduate school',
    responsibilities: 'Built 2 different regression models and compared their performance using cross-validation regression searches for relationships among variables',
    technologies: ['Python', 'Machine Learning'],
    pagelink: 'https://github.com/margaretnigh/RegressionML',
    images: [
      regression1,
      regression2,
      regression3,
      regression4,
      regression5,
      regression6,
    ],
  },

  {
    title: 'Artwork Recommender',
    description: 'Content Based Filtering for the "Best Artworks of All Time", Python program where you input an artwork image and the program will output similar looking images.',
    responsibilities: 'Pre-trained ResNet50 model for feature extraction with GPU acceleration and data augmentation',
    technologies: ['Python', 'Machine Learning'],
    pagelink: '',
    images: [
      recommend4,
      recommend5,
      recommend1,
      recommend2,
      recommend3,
    ],
  },

  {
    title: 'Pathfinding Research Project',
    description: 'Node based pathfinding project comparing multiple popular machine learning pathfinding algorithms on a random map for best walking/driving paths',
    responsibilities: 'Worked on implementing algorithms and solving bugs with a team',
    technologies: ['Python', 'Machine Learning'],
    pagelink: '',
    images: [
      pathfinder,
    ],
  },

  {
    title: 'UNICEF Human-Robot Interaction Research',
    description: 'Design and study of social robots for diverse user populations with UNICEF',
    responsibilities: 'Conducted research on the impact of robot design on children’s perceptions, rights, and privacy. Executed data collection, collaboration, study, content design, and data analysis',
    technologies: ['Research', 'Robotics'],
    pagelink: 'https://cewit.indiana.edu/programs/reuw/projects/2021-2022/49',
    images: [
      robot,
    ],
  },

  {
    title: 'Employee Payroll Application Prototype',
    description: 'Ideated redesign of employee payroll application by implementing enhancements and UX prototype.',
    responsibilities: 'Presented to team on possible future enhancement of employee payroll application using newer technologies and dashboard one-stop-shop design.',
    technologies: ['UX/UI Design', 'Figma'],
    pagelink: '',
    images: [],
  },

  {
    title: 'World Wide Technology Learning Paths Designs',
    description: 'Ideated Learning Paths ideas and inital plans',
    responsibilities: 'Brainstormed and wireframed learning path courses, later releasing to thousands of customers and employees.',
    technologies: ['UX/UI Design', 'Figma'],
    pagelink: 'https://www.wwt.com/learning-paths',
    images: [],
  },

  {
    title: 'Java Projects: Routing Wires Algorithms and DNA Alignment Algorithms',
    description: 'Various small projects from Advanced Data Structures and Algorithms course.',
    responsibilities: 'My algorithm works well with placing the shortest distance goals first before the longer distance goals, which eliminates wires blocking other wires on the board. This makes it so that all wires will be placed with respect to the board.',
    technologies: ['Java', 'Data Structures', 'Algorithms'],
    pagelink: 'https://github.com/margaretnigh/routing_wires_on_chip',
    images: [
      dna1,
      dna2,
      dna4,
      routing1,
      routing2,
      routing3,
    ],
  },

  {
    title: 'Employee Engagement Dashboard Prototype',
    description: 'Enhanced Onboarding and Employee Engagement Dashboard design as a Figma prototype, Overall Winner for Intern Capstone Competition',
    responsibilities: 'Designed Dashboard Prototype for Three Month Onboarding Plan and Employee Engagement including company wide discussions and polls, to-do plans for new hires and existing required training.',
    technologies: ['UX/UI Design', 'Figma'],
    pagelink: '',
    images: [],
  },
];

function Projects() {
  return (
    <section id="projects">
      <section className="projects">
      <div className="container">
        <h1>Projects</h1>
        {/* Render Project component for each project object */}
        {projects.map((project, index) => (
          <Project key={index} {...project} />
        ))}
      </div>
      </section>
    </section>
  );
}

export default Projects;
