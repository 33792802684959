import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Resume from './components/Resume';
import ArtworkGallery from './components/ArtworkGallery';

function App() {
  return (
    <div>
      <Header />
      <main className="py-1">
        <About />
        <Projects />
        <Resume />
        <ArtworkGallery />
      </main>
      <footer>
        <p>&copy; 2023 Margaret Nigh</p>
      </footer>
    </div>
  );
}

export default App;
