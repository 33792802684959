import React from 'react';
import margaretnigh from '../images/margaretnigh.jpg';
import resume from '../images/resume2023margaretnigh.pdf';

function About() {
  return (
    <section id="about">
      <div className="about">
      <div className="container">

        <div style={{ display: 'flex', height: '100px' }}>
          <div style={{ width: '50%'}}>
            <img src={margaretnigh} alt="Me!" style={{ maxWidth: '300px', height: 'auto', marginRight: '20px' }}/>
          </div>
          <div style={{ flexGrow: 1}}>
            <h1>Hi, I'm Margaret Nigh!</h1>
            <p>Creative Developer and Curious Designer</p>
            <h4>Junior studying Computer Science at Indiana University</h4>
            <p>
              Hey there! I'm an enthusiastic computer science student who loves diving into exciting projects.
              With a knack for creativity, I enjoy designing, coding, and building things that make a positive impact in my community. 
              I thrive when working closely with a tight-knit team, and I find great joy in helping others along the way. 
              I embrace new obstacles and adapt quickly to overcome them. 
              With my strong work ethic and a genuine desire to grow, I'm ready to leave my mark on the world of technology!
            </p>
            <div className="icon-container">
              <div className="icon-item">
                <a href={resume}>Resume PDF</a>
              </div>
              <div className="icon-item">
                <a href="mailto:margaretnigh75@gmail.com">Email Me</a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}

export default About;
