import React from 'react';
import logo from '../images/logo.png';

function Header() {
  return (
    <header className="header">
        <div className="logo">
          <img src={logo} alt="Logo"/>
        </div>
        <ul className="nav-links">
          <li><a href="#header">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><a href="#artwork_gallery">Artwork</a></li>
          <li><a href="#resume">Resume</a></li>
        </ul>
    </header>
  );
}

export default Header;
