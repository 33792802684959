import React from 'react';
import { Link, BrowserRouter as Router } from 'react-router-dom';

function Project({ images, title, description, responsibilities, technologies, pagelink, company }) {
  return (
    <Router>
    <div className="project">
      <div className="project-details">
        <h3>{title}</h3>
        <p>{description}</p>
        <h5>Responsibilities</h5>
        <p>{responsibilities}</p>
        <div className="technologies">
          {technologies.map((tech, index) => (
            <span key={index}>{tech}</span>
          ))}
        </div>
        <Link to={pagelink}>{pagelink}</Link>
      </div>
      <div className="gallery">
        {images.map((imageUrl, index) => (
          <div className="gallery-item" key={index}>
            <img src={imageUrl} alt={`Project ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
    </Router>
  );
}

export default Project;
